import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

interface LegoSet {
  name: string;
  num_parts: number;
  set_img_url: string;
  set_num: string;
  year: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  search: string;
  sets: LegoSet[];

  constructor(private http: HttpClient) {
    this.sets = [];
  }

  onSearch() {
    this.http
      .get<{ results: LegoSet[] }>('https://rebrickable.com/api/v3/lego/sets', {
        headers: {
          Accept: 'application/json',
          Authorization: 'key ee788dc34b15503803a21ce4d10382f0',
        },
        params: {
          search: this.search,
        },
      })
      .subscribe((data) => (this.sets = data.results));
  }
}
