<div class="p-5 lg:w-3/4 lg:mx-auto mt-6">
  <h1 class="text-3xl mb-4">Lego Search API</h1>
  <div class="mb-4">
    <input
      [(ngModel)]="search"
      placeholder="Search term"
      name="search"
      class="border border-gray-200 px-4 py-2 rounded"
    />
    <button class="bg-green-500 px-4 py-2 rounded ml-2" (click)="onSearch()">
      Search
    </button>
  </div>
  <div class="flex flex-wrap -m-4">
    <div *ngFor="let set of sets" class="xl:w-1/4 md:w-1/2 p-4">
      <div class="bg-gray-100 p-6 rounded-lg">
        <img
          class="rounded w-full object-cover object-center mb-6"
          [src]="set.set_img_url"
          alt="content"
        />
        <h3
          class="tracking-widest text-indigo-500 text-xs font-medium title-font"
        >
          {{ set.set_num }}
        </h3>
        <h2 class="text-lg text-gray-900 font-medium title-font mb-4">
          {{ set.name }}
        </h2>
        <p class="leading-relaxed text-base">
          Number of parts: {{ set.num_parts }} Year: {{ set.year }}
        </p>
      </div>
    </div>
  </div>
</div>
